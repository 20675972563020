import videojs from 'video.js';
import * as Events from '../../utils/Events.js';
import TextTracksUtils from '../../utils/TextTracksUtils.js';
import AudioTracksUtils from '../../utils/AudioTracksUtils.js';
import SRGStreamType from '../../utils/SRGStreamType.js';
import PlayerUtils from '../../utils/PlayerUtils.js';

const Tech = videojs.getComponent('Tech');

class Chromecast extends Tech {
  constructor(options, ready) {
    super(options, ready);

    const { source } = options;

    this.currentCastSession = Chromecast.getCurrentSession;
    this.localPlayer = source.localPlayer;
    this.streamType = source.streamType;
    this.chromecastCurrentSource = source;
    this.activeTracks = {};

    this.loadMediaToCast(source);

    this.audioTrackChangeListener = this.audioTrackChangeListener.bind(this);
    this.textTrackChangeListener = this.textTrackChangeListener.bind(this);
    this.userInactive = this.userInactive.bind(this);
  }

  /**
   * Add Chromecast receiver audio tracks to the local player.
   *
   * @param {Array} tracks
   */
  addAudioTracks(tracks) {
    const localPlayerAudioTrack = this.options().source.audioTrack;

    tracks.forEach((track) => {
      const isAudioEnabled = localPlayerAudioTrack
        ? localPlayerAudioTrack.enabled
        && localPlayerAudioTrack.language === track.language : false;

      this.audioTracks().addTrack(new videojs.AudioTrack({
        id: track.trackId,
        language: track.language,
        label: track.language,
        enabled: isAudioEnabled,
        kind: track.trackId === 1 ? 'main' : 'alternative',
      }));
    });
  }

  /**
   * Activate Chromecast receiver audio au subtitles remote track.
   *
   * @param {Object} tracksObject
   * @param {Object} tracksObject.audioTrackId
   * @param {Object} tracksObject.textTrackId
   *
   *
   * @returns {undefined}
   */
  activeRemoteTrack(tracksObject) {
    if (!this.remotePlayer || !this.remotePlayer.mediaInfo) return;

    this.activeTracks = { ...this.activeTracks, ...tracksObject };

    this.editRemoteTracksInfo([
      this.activeTracks.audioTrackId,
      this.activeTracks.textTrackId,
    ]);
  }

  /**
   * Listen for audio track change event.
   *
   * @listens CHANGE
   */
  audioTrackChangeListener() {
    const currentTextTrack = AudioTracksUtils.findActiveTrack(this.audioTracks());
    const audioTrackId = this.findRemoteAudioTrackId(currentTextTrack);

    this.activeRemoteTrack({
      audioTrackId,
    });
  }

  controls() {
    if (!this.remotePlayer) {
      return false;
    }

    return false;
  }

  createCastRequestObject({
    urn, title, streamType, currentTime,
  }) {
    if (!this.currentCastSession) {
      return undefined;
    }

    const {
      MediaInfo,
      GenericMediaMetadata,
      LoadRequest,
    } = Chromecast.chromeObject.cast.media;

    const mediaInfo = new MediaInfo(urn, 'srgssr/urn');

    mediaInfo.metadata = new GenericMediaMetadata();
    mediaInfo.customData = {};

    mediaInfo.metadata.title = title;
    mediaInfo.customData.server = this.localPlayer.options().SRGProviders.dataService.baseUrl.split('/').shift();

    const request = new LoadRequest(mediaInfo);

    // At the moment DVR live streams are not supported
    // @see https://developers.google.com/cast/docs/caf_receiver/live
    if (SRGStreamType.isOnDemand(streamType)) {
      request.currentTime = currentTime;
    }

    return request;
  }

  createEl() {
    const el = super.createEl('div', {
      id: this.options().techId,
      className: 'vjs-tech vjs-tech-chromecast',
    },
    { style: `background-image: url("${this.poster()}")` });

    this.ready(() => {
      const iconEl = videojs.dom.createEl('div', {
        className: 'vjs-icon-chromecast-active icon',
      },
      {});

      const contentEl = videojs.dom.createEl('p', {
        className: 'vjs-tech-chromecast-title',
      },
      {},
      this.localize('Playing on {1}',
        [this.currentCastSession.getCastDevice().friendlyName]));

      const titleContainer = videojs.dom.createEl('div', {
        className: 'vjs-tech-chromecast-container',
      },
      {},
      [iconEl, contentEl]);

      el.appendChild(titleContainer);
    });

    return el;
  }

  currentTime() {
    if (this.hasPendingSeek()) {
      return this.pendingSeekTime;
    }
    // CurrentTime after seek is no more updated
    // return this.remotePlayer ? this.remotePlayer.currentTime : 0;
    return this.castingMedia ? this.castingMedia.getEstimatedTime() : 0;
  }

  dispose() {
    PlayerUtils.reportUserActivity(this.localPlayer, true);
    this.localPlayer.removeClass('chromecast--connected');
    this.currentCastSession.endSession();

    this.localPlayer.off(Events.USER_INACTIVE, this.userInactive);
    this.player().audioTracks().removeEventListener(Events.CHANGE, this.audioTrackChangeListener);
    this.player().textTracks().removeEventListener(Events.CHANGE, this.textTrackChangeListener);

    if (!this.subdivisionsHidden) {
      this.localPlayer.subdivisionsContainer.show();
    }

    // eslint-disable-next-line
    this.resetSrc_(Function.prototype);
    super.dispose(this);
  }

  duration() {
    return this.remotePlayer.duration;
  }

  /**
   * Activate audio and or subtitle tracks on Chromecast receiver.
   *
   * @param {Array} trackIds
   */
  editRemoteTracksInfo(trackIds = []) {
    const { chromeObject: { cast: { media } } } = Chromecast;

    this.castingMedia.editTracksInfo(
      new media.EditTracksInfoRequest(trackIds.filter(trackId => trackId)),
    );
  }

  ended() {
    const { IdleReason } = Chromecast.chromeObject.cast.media;

    return this.castingMedia
      ? (this.castingMedia.idleReason === IdleReason.FINISHED) : false;
  }

  /**
   * Find the id of a Chromecast receiver subtitle track from a TextTrack object.
   *
   * @param {AudioTrack} track
   * @returns {Number} Chromecast receiver track id
   */
  findRemoteAudioTrackId(track) {
    if (!track) return undefined;

    const [{ trackId } = {}] = this.remotePlayerAudioTracks()
      .filter(({ language }) => track.language === language);

    return trackId;
  }

  /**
   * Find the id of a Chromecast receiver subtitle track from a TextTrack object.
   *
   * __FYI__
   * - __Chromecast receiver__, Dash remote text tracks doesn't provide a name
   * - __Dash.js__ seems to remove all _ (underscore) from the language property
   * - __HLS__ remote text tracks doesn't work when AES encryption is used
   *
   * @param {TextTrack} track
   * @returns {Number} Chromecast receiver track id
   */
  findRemoteTextTrackId(track) {
    if (!track) return undefined;

    const normalizeLanguage = language => [language, language.replaceAll('_', '')];
    const [{ trackId } = {}] = this.remotePlayerTextTracks()
      .filter(({ language, name }) => {
        const normalizeRemoteLanguage = normalizeLanguage(language);
        const hasLanguage = normalizeRemoteLanguage.includes(track.language);

        return (hasLanguage
          && track.label === name)
          || hasLanguage;
      });

    return trackId;
  }

  hasPendingSeek() {
    return ['PAUSED', 'BUFFERING'].includes(this.lastState) && this.pendingSeekTime;
  }

  initListeners() {
    this.player().textTracks().addEventListener(Events.CHANGE, this.textTrackChangeListener);
    this.audioTracks().addEventListener(Events.CHANGE, this.audioTrackChangeListener);

    const playerStateChanged = (playerState) => {
      if (this.ended() && playerState.value === 'IDLE') {
        this.trigger(Events.ENDED);
        this.remotePlayerController.removeEventListener(
          this.remotePlayerEventType.PLAYER_STATE_CHANGED, playerStateChanged,
        );
      }

      if (['BUFFERING', 'PAUSE'].includes(this.lastState) && this.isSeeking) {
        this.isSeeking = false;
        this.trigger(Events.SEEKED);
      }
      this.lastState = playerState.value;
    };

    this.remotePlayerController.addEventListener(
      this.remotePlayerEventType.PLAYER_STATE_CHANGED,
      playerStateChanged,
    );

    const startPlayBack = () => {
      this.setMuted(this.options().source.muted);
      this.trigger(Events.VOLUME_CHANGE);

      this.remotePlayerController.removeEventListener(
        this.remotePlayerEventType.CURRENT_TIME_CHANGED, startPlayBack,
      );
    };

    this.remotePlayerController.addEventListener(
      this.remotePlayerEventType.CURRENT_TIME_CHANGED,
      startPlayBack,
    );
  }

  loadMediaToCast(source) {
    this.currentCastSession
      .loadMedia(this.createCastRequestObject(source))
      .then(() => {
        const {
          RemotePlayer,
          RemotePlayerController,
          RemotePlayerEventType,
        } = Chromecast.castObject.framework;

        this.remotePlayer = new RemotePlayer();
        this.remotePlayerController = new RemotePlayerController(this.remotePlayer);
        this.remotePlayerEventType = RemotePlayerEventType;
        this.castingMedia = this.currentCastSession.getMediaSession();

        this.addAudioTracks(this.remotePlayerAudioTracks());
        this.activeRemoteTrack({
          audioTrackId: this.findRemoteAudioTrackId(
            AudioTracksUtils.findActiveTrack(this.audioTracks()),
          ),
          textTrackId: this.findRemoteTextTrackId(
            this.options().source.textTrack,
          ),
        });

        this.isCasting = true;
        this.pendingSeekTime = 0;

        this.triggerReady();
        this.trigger(Events.PLAY);
        this.trigger(Events.PLAYING);

        this.ready(this.loadMediaToCastReady.bind(this, source));
        this.initListeners();
      },
      (errorCode) => {
        window.console.log(`Error code: ${errorCode}`);
      });
  }

  loadMediaToCastReady(source) {
    PlayerUtils.reportUserActivity(this.localPlayer, false);
    this.localPlayer.on(Events.USER_INACTIVE, this.userInactive);

    this.subdivisionsHidden = this.localPlayer.subdivisionsContainer.isHidden();

    if (!this.subdivisionsHidden) {
      this.localPlayer.subdivisionsContainer.hide();
    }

    this.localPlayer.addClass('chromecast--connected');

    // DVR not supported
    if (!SRGStreamType.isOnDemand(source.streamType)) {
      this.localPlayer.liveTracker.startTracking();
      this.localPlayer.removeClass('vjs-liveui');
      this.localPlayer.addClass('vjs-live');
      this.localPlayer.controlBar.liveDisplay.show();
    }
  }

  muted() {
    return this.remotePlayer.isMuted;
  }

  pause() {
    if (!this.remotePlayer) {
      return;
    }

    if (!this.remotePlayer.isPaused) {
      this.remotePlayerController.playOrPause();
      this.trigger(Events.PAUSE);
    }

    // At the moment pause is not supported in live streams
    // @see https://developers.google.com/cast/docs/caf_receiver/live
    if (!SRGStreamType.isOnDemand(this.streamType)) {
      this.remotePlayerController.stop();
      this.currentCastSession.endSession(true);
    }
  }

  paused() {
    if (this.ended()) {
      return true;
    }

    return this.remotePlayer.isPaused;
  }

  playbackRate() {
    return this.castingMedia ? this.castingMedia.playbackRate : 1;
  }

  play() {
    if (!this.remotePlayer) {
      return;
    }

    if (this.remotePlayer.isPaused) {
      this.remotePlayerController.playOrPause();
      this.trigger(Events.PLAY);
      this.trigger(Events.PLAYING);
    }

    // Replay
    if (this.ended()) {
      this.trigger(Events.WAITING);
      this.chromecastCurrentSource.currentTime = 0;
      this.loadMediaToCast(this.chromecastCurrentSource);
    }
  }

  poster() {
    return this.options().poster;
  }

  readyState() {
    if (this.remotePlayer.playerState === 'IDLE' || this.remotePlayer.playerState === 'BUFFERING') {
      return 0;
    }
    return 4;
  }

  /**
   * Get Chromecast receiver audio tracks.
   *
   * @returns {Array}
   */
  remotePlayerAudioTracks() {
    return this.remotePlayerTracks().filter(({ type }) => type === 'AUDIO');
  }

  /**
   * Get Chromecast receiver text tracks.
   *
   * @returns {Array}
   */
  remotePlayerTextTracks() {
    return this.remotePlayerTracks().filter(({ type }) => type === 'TEXT');
  }

  /**
   * Get Chromecast receiver tracks, video, audio and subtitles.
   *
   * @returns {Array}
   */
  remotePlayerTracks() {
    return this.remotePlayer.mediaInfo.tracks;
  }

  // eslint-disable-next-line
  resetSrc_(callback) {
    if (!this.remotePlayer) {
      callback();
    }

    callback();
  }

  seekable() {
    const { end, start } = this.castingMedia.getEstimatedLiveSeekableRange;

    return videojs.createTimeRanges(0, end - start);
  }

  seeking() {
    return this.isSeeking;
  }

  setAutoplay() {
    if (!this.remotePlayer) {
      return undefined;
    }

    return undefined;
  }

  setCurrentTime(time) {
    const duration = this.duration();

    if (time > duration || !this.remotePlayer.canSeek) {
      return;
    }

    this.pendingSeekTime = time;

    // Seeking to any place within (approximately) 1 second of the end of the item
    // causes the Video.js player to get stuck in a BUFFERING state. To work around
    // this, we only allow seeking to within 1 second of the end of an item.
    this.remotePlayer.currentTime = Math.min(duration - 1, time);
    this.remotePlayerController.seek();
    this.isSeeking = true;

    this.trigger(Events.TIME_UPDATE);
    this.trigger(Events.SEEKING);
  }

  setMuted(isMuted) {
    if (this.remotePlayer.isMuted !== isMuted) {
      this.remotePlayerController.muteOrUnmute();
      this.trigger(Events.VOLUME_CHANGE);
    }
  }

  setPlaybackRate() {
    if (!this.remotePlayer) {
      return undefined;
    }

    return undefined;
  }

  setSrc(source) {
    if (source) {
      this.loadMediaToCast(source);
    }
    return this.chromecastCurrentSource;
  }

  setVolume(volumeLevel) {
    this.remotePlayer.volumeLevel = volumeLevel;
    this.remotePlayerController.setVolumeLevel();

    this.trigger(Events.VOLUME_CHANGE);
  }

  src(source) {
    if (source) {
      this.loadMediaToCast(source);
    }
    return this.chromecastCurrentSource;
  }

  supportsFullScreen() {
    if (!this.remotePlayer) {
      return false;
    }

    return false;
  }

  /**
   * Listen for text track change event.
   *
   * @listens CHANGE
   */
  textTrackChangeListener() {
    const currentTextTrack = TextTracksUtils.findActiveTrack(this.textTracks());
    const textTrackId = this.findRemoteTextTrackId(currentTextTrack);

    this.activeRemoteTrack({
      textTrackId,
    });
  }

  userInactive() {
    if (this.localPlayer.hasClass('chromecast--connected')) {
      PlayerUtils.reportUserActivity(this.localPlayer, false);
    }
  }

  volume() {
    return this.remotePlayer.volumeLevel;
  }

  static get castObject() {
    return window.cast || undefined;
  }

  static get chromeObject() {
    return window.chrome || undefined;
  }

  static get getCurrentSession() {
    return Chromecast
      .castObject && Chromecast
      .castObject
      .framework
      .CastContext
      .getInstance()
      .getCurrentSession();
  }

  static get isCasting() {
    return Boolean(Chromecast
      .castObject && Chromecast
      .castObject
      .framework
      .CastContext
      .getInstance()
      .getSessionState() === 'SESSION_STARTED');
  }
}

videojs.registerTech('Chromecast', Chromecast);
export default Chromecast;
