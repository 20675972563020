import videojs from 'video.js';
import * as PlayerEvents from '../../../utils/PlayerEvents.js';
import * as SRGEvents from '../../../utils/SRGEvents.js';
import * as Events from '../../../utils/Events.js';
import SRGLetterboxConfiguration from '../../../utils/SRGLetterboxConfiguration.js';
import { PLAYER_BAR_CLASS } from '../../../utils/SRGLetterboxLayout.js';
import RecommendationGrid from './recommendation-grid-component.js';
import ContinuousPlayback from './continuous-playback-component.js';
import Replay from './replay-component.js';
import Media from '../../../dataProvider/model/Media.js';

const Component = videojs.getComponent('Component');

/**
 * Number of seconds before when seeking to show end screen as ENDED event is not sent when seeking
 * to end of stream while paused.
 *
 * @type {number}
 */
const TOLERANCE = 0.5;

const CLASS_SHOWN = 'srgssr-end-screen--show';

/**
 *
 * @ignore
 */
class EndScreen extends Component {
  constructor(player, options) {
    super(player, options);

    this.recommendationComponent = new RecommendationGrid(this.player());
    this.continuousPlaybackComponent = new ContinuousPlayback(this.player());
    this.replayComponent = new Replay(this.player());

    this.mountedComponent = null;

    this.buildEndScreen = this.buildEndScreen.bind(this);
    this.hideAndReset = this.hideAndReset.bind(this);
    this.cancelContinuousPlayback = this.cancelContinuousPlayback.bind(this);
    this.initListeners(player);
  }

  /**
   * Builds the end screen when the media played is over.
   * It can show a recommendation grid or the continuous playback component.
   * @return {MediaList}
   */
  async buildEndScreen() {
    if (!this.isPlayerAtEnd() || this.isPlayerBarMode() || this.player().scrubbing()) {
      return;
    }

    this.addClass(CLASS_SHOWN);

    if (this.isContinuousPlaybackEnabled()) {
      this.mountContinuousPlaybackComponent();
    } else if (this.areRecommendationsEnabled()) {
      this.mountRecommendationGridComponent();
    } else {
      this.mountReplay();
    }
  }

  cancelContinuousPlayback() {
    this.unmountCurrentComponent();
    this.off(
      this.continuousPlaybackComponent,
      SRGEvents.CONTINUOUSPLAYBACK_CANCEL,
      this.cancelContinuousPlayback,
    );

    if (this.areRecommendationsEnabled()) {
      this.mountRecommendationGridComponent();
    } else {
      this.mountReplay();
    }
  }

  mountContinuousPlaybackComponent() {
    this.addChild(this.continuousPlaybackComponent);
    this.mountedComponent = this.continuousPlaybackComponent;
    this.continuousPlaybackComponent.mount({
      mediaList: this.mediaList,
      recommendationId: this.options().recommendationId,
    });
    // Cancel continuous playback if the user click on the cancel button
    this.on(this.continuousPlaybackComponent,
      SRGEvents.CONTINUOUSPLAYBACK_CANCEL,
      this.cancelContinuousPlayback);
  }

  mountRecommendationGridComponent() {
    this.addChild(this.recommendationComponent);
    this.mountedComponent = this.recommendationComponent;
    this.recommendationComponent.mount({
      mediaList: this.mediaList,
      recommendationId: this.options().recommendationId,
    });
  }

  mountReplay() {
    this.addChild(this.replayComponent);
    this.mountedComponent = this.replayComponent;
    this.replayComponent.mount();
  }

  /**
   * Get a MediaList.
   * The MediaList can be a recommendation or a trending depending the BU.
   * @return {MediaList}
   */
  async getMediaList() {
    const { vendor, id, mediaType } = this.player()
      .options()
      .SRGProviders.mediaComposition.getMainChapter();

    const { dataService } = this.player().options().SRGProviders;

    return dataService.getEndScreenList(vendor, mediaType, id);
  }

  initListeners(player) {
    // Build the end screen when needed
    this.on(player, [PlayerEvents.ENDED, PlayerEvents.SEEKED], this.buildEndScreen);

    this.on(player, Events.FIRST_PLAY, this.loadMediaSet);

    // Reset when an item is clicked
    this.on(player, [
      PlayerEvents.LOAD_START,
      PlayerEvents.PLAYING,
      PlayerEvents.ERROR,
      PlayerEvents.SEEKING,
    ], this.hideAndReset);
  }

  async loadMediaSet() {
    this.mediaList = SRGLetterboxConfiguration.getSetting(this.player(), 'endScreenPlaylist');

    if ((this.mediaList && this.mediaList.length) || !this.areRecommendationsEnabled()) {
      return;
    }

    const { mediaList, recommendationId } = await this.getMediaList();

    this.options({
      recommendationId,
    });

    this.mediaList = mediaList.map(item => new Media(item));
  }

  /**
   * Hide the endScreen and remove the children.
   * @returns {undefined}
   */
  hideAndReset() {
    if (this.isShown()) {
      this.removeClass(CLASS_SHOWN);
      this.unmountCurrentComponent();
    }
  }

  unmountCurrentComponent() {
    if (this.mountedComponent) {
      this.mountedComponent.unmount();
      this.removeChild(this.mountedComponent);
    }
  }

  /**
   * Getters
   */

  isPlayerAtEnd() {
    return (
      this.player().ended() || this.player().currentTime() > this.player().duration() - TOLERANCE
    );
  }

  isContinuousPlaybackEnabled() {
    return SRGLetterboxConfiguration.getSetting(this.player(), 'continuousPlayback');
  }

  areRecommendationsEnabled() {
    return SRGLetterboxConfiguration.getSetting(this.player(), 'recommendations');
  }

  isPlayerBarMode() {
    return this.player().hasClass(PLAYER_BAR_CLASS);
  }

  isShown() {
    return this.hasClass(CLASS_SHOWN);
  }

  displayedItems() {
    return this.recommendationComponent.displayedItems();
  }

  /**
   * Overrided methods
   */

  createEl() {
    const className = this.buildCSSClass();
    const el = videojs.dom.createEl('div', {
      className,
    });

    return el;
  }

  buildCSSClass() {
    return `${super.buildCSSClass()} srgssr-end-screen`;
  }
}

videojs.registerComponent('EndScreen', EndScreen);
export default EndScreen;
