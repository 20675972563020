import videojs from 'video.js';
import BlockingReason from '../../utils/BlockingReason.js';
import SupportEmail from '../../utils/SupportEmail.json';
import PlayerUtils from '../../utils/PlayerUtils.js';

const Component = videojs.getComponent('Component');

/**
 * @class UserReportButton
 * @description Create the user report button according to the Letterbox Configuration
 * @ignore
 */
class UserReportButton extends Component {
  errors(errorType) {
    const { userReport } = this.player().options().SRGPlayerConfiguration;
    const { errors } = userReport || {};
    const isArray = Array.isArray(errors);

    if (userReport === false || (errors !== undefined && !isArray)) {
      return false;
    }

    if (errors === undefined) {
      return BlockingReason.isNotSupported(errorType);
    }

    return errors.includes(errorType);
  }

  reportTo() {
    const { userReport: { emailTo = undefined } = {} } = this
      .player().options().SRGPlayerConfiguration;

    if (emailTo) return emailTo;

    const {
      SRGProviders: { mediaComposition = undefined },
    } = this.player().options();

    if (mediaComposition && mediaComposition.getMainChapter) {
      return SupportEmail[mediaComposition.getMainChapter().vendor];
    }

    const { host } = videojs.url.parseUrl();
    const defaultEmail = Object.keys(SupportEmail).find(email => host
      .includes(email.toLocaleLowerCase()));

    return SupportEmail[defaultEmail];
  }

  mailToContent(to) {
    const subject = `subject=${this.localize('Report a technical problem')}`;
    const debugInformation = UserReportButton.emailFormat(
      PlayerUtils.getDebugInformation(this.player()),
    );
    const vjsLog = `\r\n\r\n${videojs.log.history().slice(-10).join('\r\n')}`;
    const bodyHeader = `${this.localize(
      'Issue:',
    )}\r\n\r\n----------------------------\r\n\r\n`;
    const body = `body=${encodeURIComponent([
      bodyHeader,
      debugInformation,
      vjsLog,
    ])}`;

    return `mailto:${to}?${subject}&${body}`;
  }

  update(errorType) {
    const emailTo = this.reportTo();

    if (!emailTo || !this.errors(errorType)) return undefined;

    const mailto = this.mailToContent(emailTo);

    this.setAttribute('href', mailto);

    return this.el();
  }

  createEl() {
    return videojs.dom.createEl(
      'a',
      {
        className: 'error__debug',
      },
      {
        href: '#',
        target: '_blank',
        rel: 'noopener noreferrer',
      },
      this.localize('Send bug report'),
    );
  }

  static flattenObject(obj, parentKey) {
    let result = {};

    Object.entries(obj).forEach(([key, value]) => {
      const compoundKey = parentKey ? `${parentKey}.${key}` : key;
      const isObject = typeof value === 'object';

      if (isObject) {
        result = { ...result, ...UserReportButton.flattenObject(value, compoundKey) };
      } else {
        result[compoundKey] = value || 'n/a';
      }
    });

    return result;
  }

  static emailFormat(obj) {
    return Object.entries(
      UserReportButton.flattenObject(obj),
    )
      .map(([key, value]) => `${key} : ${value}`)
      .join('\r\n');
  }
}

videojs.registerComponent('UserReportButton', UserReportButton);
export default UserReportButton;
