import * as Events from '../utils/Events.js';
import SRGStreamType from '../utils/SRGStreamType.js';

/**
 * @ignore
 */
class MediaStatistic {
  constructor(player, hostName = 'il.srgssr.ch', debug = false) {
    this.debug = debug;
    this.player = player;
    this.baseUrl = `${hostName}/integrationlayer/2.0/`;

    this.player.on(Events.SOCIAL_SHARING, this.socialSharing.bind(this));
    this.player.on(Events.FIRST_PLAY, this.clicked.bind(this));
  }

  /**
   * Build mediaStatistic URL
   *
   * @param {Object} media
   * @param {String} media.service
   * @param {String} media.bu
   * @param {String} media.id
   * @param {String} media.mediaType
   *
   * @returns {String}
   */
  buildUrl({
    service, bu, id, mediaType,
  }) {
    return `https://${this.baseUrl}${bu.toLowerCase()}/mediaStatistic/${mediaType.toLowerCase()}/${id}/${service}.json`;
  }

  /**
   * At the first play sends a clicked media after a certain threshold
   */
  clicked() {
    this.player.one(Events.PLAYING, () => {
      const { userId } = MediaStatistic;
      const {
        streamType,
        eventData,
        ...loadedMedia
      } = this.loadedMedia();
      const buildUrl = this.buildUrl({
        service: 'clicked',
        ...loadedMedia,
      });
      const payload = { eventData };
      const timeoutTreshold = this.timeoutTreshold(streamType);

      if (userId) {
        payload.userId = userId;
      }

      if (eventData) {
        setTimeout(() => {
          this.send(buildUrl, payload);
        }, timeoutTreshold);
      }
    });
  }

  /**
   * Get the loaded media independently if it comes from the pending segment or the main chapter.
   *
   * @returns {Object} media
   * @returns {String} media.bu
   * @returns {String} media.id
   * @returns {String} media.eventData
   * @returns {String} media.mediaType
   * @returns {String} media.streamType
   */
  loadedMedia() {
    const {
      bu,
      id,
      eventData,
      mediaType,
      streamType,
      pendingSegment: {
        vendor,
        eventData: segmentEventData,
        id: segmentId,
        mediaType: SegmentMediaType,
        streamType: segmentStreamType,
      } = {},
    } = this.player.currentSource();

    return {
      bu: vendor || bu,
      eventData: segmentEventData || eventData,
      id: segmentId || id,
      mediaType: SegmentMediaType || mediaType,
      streamType: segmentStreamType || streamType,
    };
  }

  /**
   * Custom logger
   *
   * @param {String} subject
   * @param  {...any} data
   */
  log(subject, ...data) {
    if (this.debug) {
      console.log( // eslint-disable-line no-console
        `MediaStatistic:${subject}`, ...data,
      );
    }
  }

  /**
   * Send the request
   *
   * @param {String} url
   * @param {Object} payload
   */
  send(url, payload) {
    this.log('socialSharing', url, payload);

    fetch(url, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(payload),
    })
      .then((response) => {
        this.log('response', response);
      });
  }

  /**
   * Sent when a share button is clicked
   *
   * @param {Object}
   */
  socialSharing({ data }) {
    const { userId } = MediaStatistic;
    const {
      eventData,
      ...loadedMedia
    } = data;
    const buildUrl = this.buildUrl({
      service: `shared/${loadedMedia.socialVendor}`,
      ...loadedMedia,
    });
    const payload = { eventData };

    if (userId) {
      payload.userId = userId;
    }

    if (eventData) {
      this.send(buildUrl, payload);
    }
  }

  /**
   * Calculate the timemout treshold according to the stream type or the media duration.
   *
   * @param {String} streamType
   * @returns {Number} 10 if media is a live stream or has a duration greater than 10, 0 otherwise.
   */
  timeoutTreshold(streamType) {
    const duration = this.player.duration();
    const hasDuration = Number.isFinite(duration) && duration > 0;
    const durationThreshold = hasDuration ? duration * 0.8 : 0;
    const isLiveStream = !SRGStreamType.isOnDemand(streamType);

    return (isLiveStream || durationThreshold > 10) ? 10000 : durationThreshold;
  }

  /**
   * Get userId property if available
   */
  static get userId() {
    try {
      const item = JSON.parse(window.localStorage.getItem('playSRG-playUserIdv2'));

      if (item) {
        const { userId } = item;

        return userId;
      }
    } catch (error) {
      return undefined;
    }

    return undefined;
  }
}

export default MediaStatistic;
