import videojs from 'video.js';
import AkamaiTokenService from '../../utils/AkamaiTokenService.js';
import Drm from '../../utils/Drm.js';
import Utils from '../../utils/Utils.js';
import SRGTokenType from '../../utils/SRGTokenType.js';
import PerformanceReport from '../../diagnostics/PerformanceReport.js';
import PerformanceService from '../../diagnostics/PerformanceService.js';
import BlockingReason from '../../utils/BlockingReason.js';
import ERRORTYPE from '../../errors/errors.js';
import { version as playerVersion } from '../../../package.json';
import MediaCompositionPeriodicListener from './mediacomposition-periodic-listener.js';
import MediaComposition from '../../dataProvider/model/MediaComposition.js';
import PlayerUtils from '../../utils/PlayerUtils.js';
import * as PlayerEvents from '../../utils/PlayerEvents.js';
import * as SRGEvents from '../../utils/SRGEvents.js';
import * as SRGQuality from '../../utils/SRGQuality.js';
import SupportedDevices from '../../utils/SupportedDevices.js';
import SRGLetterboxConfiguration from '../../utils/SRGLetterboxConfiguration.js';

/**
 * Base SRGSSR Middleware using a media composition. The media composition can be local
 * or fetched remotely.
 *
 * @param player player
 * @ignore
 */
const SrgssrMiddleware = (player) => {
  const { log } = player;

  /**
   * Track playback errors and send a report.
   */
  function playbackErrorTracker() {
    let lastReportId;

    const playbackErrorReport = () => {
      const {
        date: clientTime, lastPerformanceReportId, urn, url: location,
      } = PlayerUtils.getDebugInformation(player);

      if (!player.hasStarted()
        || lastReportId === lastPerformanceReportId) return;

      lastReportId = lastPerformanceReportId;

      const performanceService = new PerformanceService();
      const report = {
        event_name: 'playback_error',
        lastPerformanceReportId,
        browser: navigator.userAgent,
        platform: navigator.platform,
        player: `Letterbox/web/${playerVersion}`,
        clientTime,
        location,
        urn,
        position: player.currentTime(),
        source: player.currentSource().src,
        error_log: JSON.stringify(videojs.log.history().slice(-15)),
        environment: Utils.getEnvironment(),
      };

      performanceService.send(report);
    };

    player.on('error', playbackErrorReport);
    player.one('dispose', () => player.off('error', playbackErrorReport));
  }

  playbackErrorTracker();

  // TODO implement local storage
  function addExternalSubtitles() {
    const subtitles = player
      .options().SRGProviders
      .mediaComposition
      .getFilteredExternalSubtitles(log);

    subtitles.forEach((subtitle) => {
      player.addRemoteTextTrack({
        kind: subtitle.type === 'SDH' ? 'captions' : 'subtitles',
        label: subtitle.language,
        language: subtitle.locale,
        src: subtitle.url,
      });
    });
  }

  /**
   * Clear and close the error modal before loading a new source.
   */
  function clearError() {
    if (player.error() !== null) {
      player.error(null);
    }
  }

  /**
   * Initialize the performance report.
   * @param {String} urn
   */
  function createPerformanceReport(urn) {
    const performanceService = new PerformanceService();
    const performanceReport = new PerformanceReport(urn, Utils.getEnvironment(), playerVersion);
    const drmReport = () => {
      if (Drm.hasDrm([player.currentSource()])) {
        performanceReport.setDrmResult();
      }
    };

    const sendPerformanceReport = async (report) => {
      const result = await performanceService.send(report);
      const responseBody = await result.json();

      if (responseBody && responseBody.result === 'created') {
        const { _id: id } = responseBody;

        const cache = player.getCache();
        cache.lastPerformanceReportId = id;
      }
    };

    const mediaCompositionReport = () => {
      const { mediaComposition } = player
        .options()
        .SRGProviders;

      if (mediaComposition) {
        const {
          blockReason,
          playableAbroad,
        } = mediaComposition
          .getMainChapter();

        performanceReport.setIlMediaComposition({
          blockReason,
          playableAbroad,
          noPlayableResourceFound: player.currentSources().length === 0,
        });
      }
    };
    const tokenReport = () => {
      if (SRGTokenType.isProtected([player.currentSource()])) {
        performanceReport.setTokenResult(player.currentSource().tokenDiagnostic);
      }
    };

    let errorEvent;
    let isErrorReportSent = false;

    const canplaythroughEvent = () => {
      mediaCompositionReport();
      drmReport();
      tokenReport();

      performanceReport.setPlayerResult({
        url: player.currentSource().src,
        duration: false,
      });

      sendPerformanceReport(performanceReport);

      player.off(PlayerEvents.ERROR, errorEvent);
    };

    errorEvent = () => {
      mediaCompositionReport();
      drmReport();
      tokenReport();

      performanceReport.setPlayerResult({
        url: player.currentSource().src,
        httpStatusText: player.error().detailedMessage
          || player.error().type
          || player.error().message,
        duration: false,
      });

      // Avoid sending error report twice
      if (!isErrorReportSent) {
        sendPerformanceReport(performanceReport);
        isErrorReportSent = true;
      }

      player.off(PlayerEvents.CAN_PLAY_THROUGH, canplaythroughEvent);
    };

    player.one(PlayerEvents.CAN_PLAY_THROUGH, canplaythroughEvent);
    player.one(PlayerEvents.ERROR, errorEvent);

    return performanceReport;
  }

  /**
   * Remove unnecessary resources has RTMP and HDS.
   * @param {Array} resources
   */
  function filterFlashSources(resources = []) {
    return resources.filter(resource => !['RTMP', 'HDS'].includes(resource.streaming));
  }

  /**
   * Get the ResourceList from the DataProvider.
   * Pass the diagnostic object to the performance report.
   *
   * - Remove old resources such as RTMP and HDS
   * - Remove DASH resources if Safari browser
   * - Set the token if the resource if protected by a token
   *
   * @param {Array} sourceList
   * @param {PerformanceReport} performanceReport
   * @return {Object} Video.js resource list
   */
  function getResourceList(sourceList, performanceReport) {
    try {
      let resources = filterFlashSources(sourceList);

      if (!videojs.browser.IS_ANY_SAFARI && Drm.hasDrm(resources)) {
        resources = resources.filter(r => r.streaming === 'DASH');
      }

      if (videojs.browser.IS_ANY_SAFARI && Drm.hasDrm(resources)) {
        resources = resources.filter(r => r.streaming === 'HLS');
      }

      const hdMode = SRGLetterboxConfiguration.getSetting(player, 'hd');

      if (hdMode === false) {
        const sdResources = resources
          .filter(r => ![SRGQuality.types.HD, SRGQuality.types.HQ].includes(r.quality));

        if (sdResources.length > 0) {
          resources = sdResources;
        }
      }

      if (SRGTokenType.isProtected(resources)) {
        return AkamaiTokenService
          .tokenizeSources(resources)
          .then(r => r)
          .catch((reason) => {
            performanceReport.setTokenResult(reason);
            player.error(ERRORTYPE.ERROR_BLOCKING_REASON_UNKNOWN);

            return undefined;
          });
      }

      return resources;
    } catch (e) {
      performanceReport.setTokenResult(e);
      player.error(ERRORTYPE.ERROR_BLOCKING_REASON_UNKNOWN);

      return undefined;
    }
  }

  function getMediaComposition(urn, standalone, performanceReport) {
    return player.options().SRGProviders
      .dataService
      .getMediaCompositionByUrn(
        urn,
        standalone,
      ).then(
        ({ diagnostic, mediaComposition }) => {
          performanceReport.setIlResult(diagnostic);
          return mediaComposition;
        },
      ).catch(
        (e) => {
          performanceReport.setIlResult(e);
          player.error(ERRORTYPE.IL_ERROR);
          return undefined;
        },
      );
  }

  function triggerAspectRatio({ aspectRatio }) {
    if (aspectRatio) {
      player.trigger({
        type: SRGEvents.ASPECT_RATIO,
        data: { aspectRatio },
      });
    }
  }

  /**
   * Removes mediacomposition periodic listener
   */
  function clearPeriodicListener() {
    if (player.options().SRGProviders.periodicListener) {
      player.options().SRGProviders.periodicListener.stop();
    }
  }

  /**
   * Set a periodic listener
   * @param {bool} standalone
   */
  function setPeriodicListener(standalone) {
    player.options({
      SRGProviders: {
        periodicListener: new MediaCompositionPeriodicListener(
          player.options().SRGProviders.mediaComposition.chapterUrn,
          standalone,
          player,
        ),
      },
    });
    player.options().SRGProviders.periodicListener.start();
  }


  /**
   * The mediaComposition is periodically checked if it's loaded from the data provider or if it comes from the
   * periodic listener itself
   * @param {bool} periodicReload is the mediaComposition provided by the periodic listener
   * @param {MediaComposition} mediaCompositionObject the mediaComposition directly provided
   * @returns {Boolean}
   */
  function isPeriodicListenerNeeded(periodicReload, mediaCompositionObject) {
    return periodicReload || !mediaCompositionObject;
  }

  async function setSource(srcObj) {
    clearError();

    clearPeriodicListener();

    const {
      src: urn, mediaComposition: mediaCompositionObject,
      periodicReload, playbackSettings, pendingSeek, standalone,
    } = srcObj;

    let mediaComposition;
    const performanceReport = createPerformanceReport(urn);

    // It is possible possible to directly provide a mediaComposition without fetching it
    if (mediaCompositionObject) {
      mediaComposition = mediaCompositionObject;
    } else {
      mediaComposition = await getMediaComposition(urn, standalone, performanceReport);
    }

    if (mediaComposition) {
      log.debug('Media composition loaded');

      player.options({
        SRGProviders: {
          mediaComposition: Object.assign(
            new MediaComposition(),
            mediaComposition,
            {
              pendingSeek,
              playbackSettings,
            },
          ),
        },
      });

      player.trigger({
        type: SRGEvents.MEDIACOMPOSITION_LOADED,
        data: { mediaComposition: player.options().SRGProviders.mediaComposition },
      });

      triggerAspectRatio(player.options().SRGProviders.mediaComposition.getMainChapter());
    } else {
      log.warn('No media composition');
      // If the mediaComposition is undefined that means an exception was thrown.
      // Stop player's lifecycle
      return;
    }

    const resourcesList = await getResourceList(player.options().SRGProviders
      .mediaComposition
      .getMainResources(), performanceReport);

    if (isPeriodicListenerNeeded(periodicReload, mediaCompositionObject)) {
      setPeriodicListener(standalone);
    }

    // If the resourcesList is undefined that means an exception was thrown.
    // Stop player's lifecycle
    if (!resourcesList) {
      log.warn('No resource');
      return;
    }

    if (player.options().SRGProviders.mediaComposition.getMainBlockReason()) {
      player.error(
        BlockingReason.toError(player.options().SRGProviders.mediaComposition.getMainBlockReason()),
      );
      return;
    }

    addExternalSubtitles();

    player.poster(player.options().SRGProviders.mediaComposition.getMainChapterImageUrl());

    if (!SupportedDevices.isDRMSupported() && Drm.hasDrm(resourcesList)) {
      player.error(
        ERRORTYPE.ERROR_DRM_NOT_SUPPORTED_MESSAGE,
      );

      return;
    }

    player.src(resourcesList);

    if (videojs.browser.IS_ANY_SAFARI && Drm.hasDrm(resourcesList)) {
      // TODO - WARNING - workaround for safari DRM, re-initialize the EME plugin.
      // eslint-disable-next-line no-param-reassign
      player.eme = videojs.getPlugin('eme');
      player.eme();
    }

    log.debug('Srgssr middleware ready');
  }

  return {
    setSource,
    isPeriodicListenerNeeded,
  };
};

export default SrgssrMiddleware;
videojs.use('srgssr/urn', player => SrgssrMiddleware(player));
